import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

const NotFoundPage = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Adam Mindi Curls Hero"}
      h1={"Page Not Found"}
    >
      <SEO
        title="Not Found"
        keywords={["Body By Kickbush", "BBK", "Not Found", "404"]}
      />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export const query = graphql`
  query notFoundQuery {
    header: allFile(filter: { name: { eq: "adam_mindi_1200x802" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`
export default NotFoundPage
